import * as React from "react"
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col, Card, ListGroup, ListGroupItem } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ParticleAnimation from "../components/particleAnimation"
import { header, projectBody } from "../styles/work.module.css"

const WorkPage = () => {
  return (
    <Layout>
      <ParticleAnimation />
      <Container fluid>
        <Row className="vh-100">
          <Col className="align-self-center" xs={{ span: 9, offset: 2 }}>
            <h1 className={header}>Let's build something great together!</h1>
          </Col>
        </Row>
      </Container>
      <Container fluid className="text-white my-5">
        <Row className="justify-content-center mb-5">
          <Col md={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 2 }} sm={{ span: 9, offset: 2 }} className="lead text-justify">
            <p>
              After years and years of only skimming the surface of computer science
              and programming, I finally made the choice to take the deep plunge
              and begin learning all there is to know about developing software and
              web applications.
            </p>
            <p>
              Below are just a few of my latest developments built on
              this new journey.  If you would like to know more on some of my past work,
              feel free to contact me and I would be happy to share as much as I can on the
              software I created and implemented with my past company.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-around mb-5">
          <Col lg={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 2 }}>
            <h2 className="text-center">Certifications</h2>
          </Col>
          <Col lg={{ span: 4, offset: 1 }} xs={{ span: 10, offset: 2 }} className="mb-4 mb-lg-0">
            <Card border="light" bg="dark" text="white">
              <StaticImage
                src="../images/javaCert.png"
                alt="Java Programming Masterclass for Software Developers Certificate"
                placeholder="blurred"
                layout="fullWidth"
              />
              <Card.Body>
                <Card.Title className="text-center">Java Programming Masterclass for Software Developers</Card.Title>
                <Card.Text className="small">
                  Udemy{' '}
                  <Card.Link href="../../javaCert.pdf" target="_blank" rel="noreferrer">
                    See Certificate
                  </Card.Link>{' '}
                  <sup>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 32 32">
                      <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                      <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                    </svg>
                  </sup>
                </Card.Text>
                <details>
                  <summary>
                    Click here to see the skills learned:
                  </summary>
                  <ListGroup className="list-group-flush text-dark">
                    <ListGroupItem>Java 8 & 11</ListGroupItem>
                    <ListGroupItem>Object-Oriented Programming</ListGroupItem>
                    <ListGroupItem>Java API</ListGroupItem>
                    <ListGroupItem>IntelliJ IDEA IDE</ListGroupItem>
                    <ListGroupItem>JavaFX</ListGroupItem>
                    <ListGroupItem>Debugging</ListGroupItem>
                    <ListGroupItem>Unit Testing</ListGroupItem>
                    <ListGroupItem>Networking</ListGroupItem>
                    <ListGroupItem>Multi-Threading</ListGroupItem>
                    <ListGroupItem>I/O and SQL Databases</ListGroupItem>
                    <ListGroupItem>Preventing SQL Injection Attacks</ListGroupItem>
                  </ListGroup>
                </details>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={{ span: 4, offset: 1 }} xs={{ span: 10, offset: 2 }}>
            <Card border="light" bg="dark" text="white">
              <StaticImage
                src="../images/webDevCert.png"
                alt="Web Developer Bootcamp Certificate"
                placeholder="blurred"
                layout="fullWidth"
              />
              <Card.Body>
                <Card.Title className="text-center">Web Developer Bootcamp</Card.Title>
                <Card.Text className="small">
                  Udemy{' '}
                  <Card.Link href="../../webDevCert.pdf" target="_blank" rel="noreferrer">
                    See Certificate
                  </Card.Link>{' '}
                  <sup>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 32 32">
                      <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                      <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                    </svg>
                  </sup>
                </Card.Text>
                <details>
                  <summary>
                    Click here to see the skills learned:
                  </summary>
                  <ListGroup className="list-group-flush text-dark">
                    <ListGroupItem>HTML5</ListGroupItem>
                    <ListGroupItem>CSS3</ListGroupItem>
                    <ListGroupItem>Flexbox & Responsive Design</ListGroupItem>
                    <ListGroupItem>Javascript</ListGroupItem>
                    <ListGroupItem>Async and AJAX</ListGroupItem>
                    <ListGroupItem>Bootstrap 4/5, Semantic UI, & Bulma</ListGroupItem>
                    <ListGroupItem>DOM Manipulation</ListGroupItem>
                    <ListGroupItem>NodeJS & ExpressJS</ListGroupItem>
                    <ListGroupItem>Templating, REST, & CRUD</ListGroupItem>
                    <ListGroupItem>NoSQL Databases (Local & Cloud)</ListGroupItem>
                    <ListGroupItem>Authentication & Authorization</ListGroupItem>
                    <ListGroupItem>Image Upload and Storage</ListGroupItem>
                    <ListGroupItem>Maps and Geocoding</ListGroupItem>
                    <ListGroupItem>Deploying Apps</ListGroupItem>
                  </ListGroup>
                </details>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-around text-justify">
          <Col lg={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 2 }}>
            <h2 className="text-center">Projects</h2>
          </Col>
          <Col lg={{ span: 3, offset: 1 }} xs={{ span: 10, offset: 2 }} className="mb-4">
            <Card border="light" bg="dark" text="white">
              <StaticImage
                src="../images/qualityToolsLogo.png"
                alt="Quality Tools Website Landing Page"
                placeholder="blurred"
                layout="fullWidth"
              />
              <Card.Body>
                <h3 className="text-center">
                  <Card.Link href="https://qualitytools.org/" target="_blank" rel="noreferrer">
                    Quality Tools
                  </Card.Link>{' '}
                  <sup>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 32 32">
                      <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                      <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                    </svg>
                  </sup>
                </h3>
                <details>
                  <summary>
                    This site contains a web application built using the React library with Next.js
                    as the framework and Bootstrap for the design components.
                  </summary>
                  <br />
                  <Card.Text className={projectBody}>
                    It has been deployed on Vercel under my own domain with an SSL certificate for data
                    encryption and security.  The application utilizes Auth0 for user authentication
                    and Next-Auth for user authorization.  To help with the user experience, custom
                    403 and 404 pages have been created if something goes wrong with a user request.  The 403 page utilizes
                    a fun animation to alert the user that they are trying to access data they do not have
                    authorization for, while the 404 page promptly informs the user of missing page and
                    provides them with the option to play an audio clip of a dial-up modem as it tries to connect
                    them to the resource they are looking for.  Both pages also has added information to help explain
                    why this issue may have occurred and what the user's next steps should be to find what they are
                    looking for.  Utilizing SWR and Serverless MySQL data is stored and fetched from the SQL database
                    at serverless scale, which is hosted on Google Cloud
                  </Card.Text>
                  <Card.Text className={projectBody}>
                    The Five-Why tool within the application itself uses complex JavaScript logic
                    and CSS formatting to allow a user to work through their investigation and
                    determine the root cause of an issue based off their problem statement.  Several textareas
                    are used to capture basic information about the investigation while a custom React component
                    is utilized for the submission and display of answers during the investigation.  Event listeners
                    and custom logic is coded into the React component to allow for the submission of an answer from the
                    provided button or hitting the Enter key, which then transfers the answer to the answer list and displays it
                    as an indented list.  By hovering over each individual answer, an edit and delete button are also
                    presented to the user to modify their 5-Whys without having to start over from the beginning.  Through
                    the use of the Tiny MCE library, users are also allowed to create rich text notes as they proceed through their
                    investigation, complete with formatting, bulleted/numbered lists, symbols, and links to external webpages.  After
                    logging into the system, the user is allowed to save their Five-Whys for future
                    editing, deleting, and printing in a clean format.  This save function captures all relevant information,
                    on the investigation, including the title, problem statement, root cause, answer list, and notes.
                    The application also provides an authenticated user to search through their saved Five-Whys using a convenient search
                    bar or sort them all by various criteria.  All of this is built with a minimalistic
                    mindset to avoid clutter and help the user and their team stay focused on the issue
                    and work through the process efficiently and effectively.
                  </Card.Text>
                </details>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={{ span: 3, offset: 0 }} xs={{ span: 10, offset: 2 }} className="mb-4">
            <Card border="light" bg="dark" text="white">
              <StaticImage
                src="../images/yelpCampLogo.png"
                alt="Yelp Camp Website Landing Page"
                placeholder="blurred"
                layout="fullWidth"
              />
              <Card.Body>
                <h3 className="text-center">
                  <Card.Link href="https://yelp-camp-nine.vercel.app/" target="_blank" rel="noreferrer">
                    YelpCamp
                  </Card.Link>{' '}
                  <sup>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 32 32">
                      <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                      <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                    </svg>
                  </sup>
                </h3>
                <details>
                  <summary>
                    This website was built following the final tutorial from the Web Developer
                    Bootcamp course on Udemy and is my first officially deployed application.
                  </summary>
                  <br />
                  <Card.Text className={projectBody}>
                    Utilizing a combination of Express, EJS, Node.js, and Bootstrap for the
                    framework, this site has been deployed on Vercel with a domain name provided
                    by them.  The application utilizes Passport for user authentication
                    and custom built middleware for user authorization with sessions for tracking necessary user information
                    across the site. A simple 404 page is utilized to inform the users
                    that they have reached a page that does not exist.  All images are stored in Cloudinary
                    for quick retrieval of images cropped and scaled as needed using Virtual Schemas.
                    A NoSQL database was chosen for this project through MongoDB, which is utilized to
                    store all information related to the authenticated user and campgrounds on the website.  Mongoose
                    handles the object modeling of our database and the retrieval of data, as needed.  Joi and Sanitizie-HTML helps
                    ensure that the data being entered into the database is clean and meets the necessary requirements.
                  </Card.Text>
                  <Card.Text className={projectBody}>
                    YelpCamp is a comprehensive tool designed to capture campgrounds anywhere within the world
                    based off user's inputting basic information, such as title, location, price, description, and images.  Utilizing
                    user authentication and authorization, the site captures who submitted the campground and prevents
                    other users from updating the information without approval.  This also allows for the submission
                    of reviews on the individual campgrounds with a rating system as well.  Full CRUD functionality
                    is available on the campgrounds and reviews, with all reviews related to a specific campground being
                    removed on deletion of the campground itself.  When entering the site, a clean, minimalistic landing page is
                    shown, giving the user the option to view the current campgrounds or login/register for additional features.
                    Upon viewing all campgrounds a clustermap is provided, through MapBox, which showcases a quick
                    breakdown of all campgrounds currently saved in the database.  From the view page, you can scroll through the list
                    of all campgrounds or click on the clusters of the map to view campgrounds in a specific area.  When a specific
                    campground is found on the map, clicking its pin will provide you with the name of the campground, a
                    truncated section of the description, and a link to take you to the campground's page.
                  </Card.Text>
                </details>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={{ span: 3, offset: 0 }} xs={{ span: 10, offset: 2 }} className="mb-4">
            <Card border="light" bg="dark" text="white">
              <StaticImage
                src="../images/blogLogo.png"
                alt="Blog Page of Personal Site"
                placeholder="blurred"
                layout="fullWidth"
              />
              <Card.Body>
                <h3 className="text-center">
                  <Card.Link href="https://corydorfner.com/blog" target="_blank" rel="noreferrer">
                    Personal Blog
                  </Card.Link>{' '}
                  <sup>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 32 32">
                      <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                      <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                    </svg>
                  </sup>
                </h3>
                <details>
                  <summary>
                    Built directly within this personal website, my blog component makes use of the Gastby framework and GraphQL to
                    transform Markdown files into fully-fledged blog pages.
                  </summary>
                  <br />
                  <Card.Text className={projectBody}>
                    All Markdown files and blog images are stored directly within the codebase, eliminating the need for a database layer
                    and third-party Content Management System (CMS). Using Gatsby as a Static Site Generator (SSG), all pages are generated
                    at build time, allowing for an incredibly fast and responsive site. All individual blog posts, as well as the list of posts,
                    are delivered to the user as mostly HTML, with a little bit of JavaScript, utilizing only template files and GraphQL. This
                    greatly reduces the network bandwidth needed to load the site, providing page loads in milliseconds with a clean and
                    minimilistic UI. To provide readers with the ability to add feedback and suggestions on my posts, I've implemented Disqus,
                    further reducing the need for a database layer. This also eliminates the need for any authorization and authentication,
                    as the viewers of my posts will utilize their existing credentials with the provider to comment. All of these steps
                    help contribute to a fast, cheap, and user-friendly blog site.
                  </Card.Text>
                  <Card.Text className={projectBody}>
                    This personal blog page is a simple, yet effective tool to allow an individual
                    to express their ideas and opinions without fear of others gaining access to their
                    content and making unauthorized changes to it. A simple, minimalistic user interface
                    helps the readers of the content find what they are looking for,
                    without distractions, and comfortably move throughout the site. All content for the blog posts are
                    stored solely within the Markdown file itself, where YAML frontmatter is used to store the
                    metadata for the posts. All content from the Markdown file is pulled into, and utilized
                    by the template file, through GraphQL. From the blog page, the latest 10 blog posts are displayed,
                    with buttons for pagination at the bottom of the list. A banner at the top of the page presents
                    the reader with common tags used on blog posts, allowing them to refine their view to more
                    applicable posts. Readers also have the option to navigate directly to a separate Series
                    page, where they can view all posts related to specific series. After selecting the desired blog post,
                    a user can leave comments on the content utilizing Disqus' comment plugin.  With this simple
                    tool, users can share their feedback directly within the site and the site admin has access
                    to advanced moderation tools.
                  </Card.Text>
                </details>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-around my-5 lead">
          <Col lg={{ span: 5, offset: 1 }} xs={{ span: 8, offset: 1 }} className="mb-3">
            <p>
              <strong>Soft Skills:</strong>
            </p>
            <ul>
              <li>Strong member in any team-setting with a collaborative and open mindset</li>
              <li>Excellent problem-solving skills with an eye always on the future</li>
              <li>Extensive knowledge of lean and agile methodologies</li>
              <li>Effective written and verbal communication</li>
              <li>Solid ability to manage time and resources</li>
              <li>Practicing of mindful meditation to manage stress</li>
              <li>Efficient organization skills, both physically and digitally</li>
              <li>Adaptable to changing environments and situations</li>
              <li>Impeccable ability to critically think and apply solutions through effective decision-making</li>
              <li>Proven leadership abilities with a strong ability to manage conflicts</li>
              <li>Always open to receive and provide positive and useful feedback</li>
              <li>Passion for learning and building up others for everyone's success</li>
            </ul>
          </Col>
          <Col lg={{ span: 5, offset: 0 }} xs={{ span: 8, offset: 1 }} className="mb-3">
            <p>
              <strong>Hard/Technical Skills:</strong>
            </p>
            <Row>
              <Col md={6}>
                <ul className="mb-0">
                  <li>Java</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>JavaScript</li>
                  <li>React</li>
                  <li>Bootstrap</li>
                  <li>Next.js</li>
                  <li>Gatsby</li>
                  <li>Express</li>
                  <li>Node.js</li>
                  <li>Git</li>
                </ul>
              </Col>
              <Col md={6}>
                <ul className="mb-0">
                  <li>MySQL</li>
                  <li>MongoDB</li>
                  <li>Google Cloud Platform</li>
                  <li>Microsoft Office</li>
                  <li>SharePoint</li>
                  <li>SAP</li>
                  <li>Familiar with jQuery</li>
                  <li>Familiar with TypeScript</li>
                  <li>Familiar with PHP</li>
                  <li>Familiar with Python</li>
                  <li>Familiar with React Native</li>
                  <li>Familiar with C and C++</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export const Head = (props) => {
  const pageSeo = {
    title: "Work",
    pathname: props.location.pathname,
  }

  return (
    <Seo pageSeo={pageSeo} />
  )
}

export default WorkPage